.feature-book{
	height: 600px;
	padding:40px 15%;
	box-sizing: border-box;
	background-color:rgba(217, 217, 217, 1);
}

.feature-book .dot-stepper{
	border-radius:50%;
	background-color:white;
	width:12px;
	height:12px;
	margin:0 5px;
	cursor: pointer;
}

.feature-book-skeleton{
	background-color: rgb(250, 250, 250) !important;
}

.feature-book-cover{
	padding: 50px 0;
	width:100%;
	align-items: center;
	justify-content: center;
}

.feature-book-cover img{
	object-fit: cover;
	height:100%;
	max-width: 320px;
}

.feature-book-description{
	padding: 50px 0;
	width:100%;
	align-items: flex-start;
	justify-content: center;
}

.feature-book .react-swipeable-view-container{
	height:inherit;
}

.book-container{
	cursor: pointer;
	box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1);
}

.new-drops{
	padding: 40px 100px;
	margin: 70px 0;
}

.new-drop-container{
	max-width: 150px;
}

.new-drops-cover img{
	object-fit: cover;
	height:100%;
	width:100%;
}

.intro-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-gap: 5rem;
	padding: 100px;
}

.intro-container .right {
	padding: 0 50px;
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.parallax {
	margin: 140px 0 70px 0;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}


.footer {
	padding: 100px 100px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 30%;
}

.new-drop-items{
	flex-wrap: wrap;
	align-items: flex-start;
}

.new-drop-item{
	width: 33%;
	justify-content: start;
	align-items: center;
}

.new-drop-item img{
	max-width:150px;
}

@media (max-width: 990px) {
	.intro-container {
		grid-gap: 2rem;
		grid-template-columns: 100%;
		padding: 50px;
	}

	.intro-container .right {
		padding: 0;
		justify-content: center;
    	align-items: center;
	}

	.parallax {
		margin: 70px 0;
		background-position: start;
		background-attachment: inherit;
	}

	.new-drops{
		padding: 40px 5%;
	}

	.new-drop-container{
		max-width: 150px;
	}

	.footer {
		padding: 50px;
		grid-template-columns: 1fr;
		gap: 50px;
	}

	.feature-book-description h4{
		font-size: 24px;
	}

	.feature-book-cover .feature-book-skeleton{
		width: 150px !important;
	}

	.feature-book-description .feature-book-skeleton{
		width: 300px !important;
	}

	.feature-book-cover .feature-book-skeleton, .feature-book-container img{
		height: 300px !important;
		max-width: 200px !important;
	}

	.new-drop-item span{
		width: 180px !important;
	}
	
}

@media (max-width: 650px) {
	.feature-book{
		height:100%;
	}

	.feature-book-description .feature-book-skeleton{
		width: 300px !important;
	}

	.feature-book-cover .feature-book-skeleton, .feature-book-container img{
		height: 200px !important;
	}

	.feature-book-container{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}


	.feature-book-cover, .feature-book-description{
		padding: 10px 0;
		justify-content: center;
		align-items: center;
	}

	.new-drop-item {
		width: 100%;
	}

	.new-drop-items{
		align-items: center;
		flex-direction: column;
	}
}
