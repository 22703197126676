body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height:100vh;
  background-color:#f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.yellow {
	width: 250px;
	display: inline-block;
	text-align: center;
	padding: 10px 0;
	background-color: #FCEE21 !important;
	color: black !important;
	text-decoration: none;
}

.MuiFormControl-root{
  /* border-radius: 30px; */
  background-color: #fff;
  margin-bottom: 60px !important;
  min-width: 320px !important;
  max-width: 600px !important;
}

.MuiButton-root:hover, .MuiMenuItem-root:hover {
  background-color: #d3f0d4 !important;
  /* color: white; */
}

.MuiFormControl-root .MuiInputBase-root{
  /* border-radius: 30px !important; */
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.MuiFormControl-root label{
  color:#8f8fa1;
  letter-spacing: 2px;
  font-weight: 800;
  font-size:20px;
  opacity: 0.75;
}


.MuiFormControl-root label.MuiFormLabel-filled, .MuiFormControl-root label.Mui-focused{
  top: -15px !important;
}

.MuiInputBase-inputMultiline{
  min-height:150px;
  max-height:400px;
  overflow:auto;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  transform: scaleX(1.05);
  background-color: #fff;
  z-index: -1;
}

.MuiInputLabel-root.Mui-focused{
  color:#8f8fa1 !important;
  opacity:1;
}

.MuiOutlinedInput-notchedOutline{
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border-color:transparent!important;
}

.MuiFormControl-root .MuiFormHelperText-root{
  position: absolute;
  bottom: -25px;
}

.MuiFormControl-root textarea{
  overflow:auto !important;
}

.space-evenly{
  justify-content: space-evenly;
}


.space-between{
  justify-content: space-between;
}

.flex-row{
  display: flex;
  width: 100%;
}

.flex-column{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-center{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.flex-auto{
  flex: 1 1 auto;
}

.MuiButton-sizeLarge{
  min-width: 150px !important;
  margin: 10px !important;
  padding: 10px !important;
  /* border-radius: 30px !important; */
}

.book-item{
  overflow: hidden;
  position: relative;
  cursor: pointer;
} 

.book-item .book-item-overlay{
  top: 0;
  position: absolute;
  width:100%;
  height: 100%;
  z-index:-1;
  background-color: black;
}

.book-item img{
  transition: all 0.4s;
  opacity:0.9;
}

.book-item img:hover{
  transform:scale(1.1);
  opacity: 1;
}

.neon-light{
  box-shadow:0 0 7px #5271ff;
  border-radius: 50%;
}
