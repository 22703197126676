	.row-flex{
		display: flex;
	}

	.auto-flex{
		flex: 1 1 auto;
	}

	.utility-icon{
		margin: 0 10px;
		cursor: pointer;
	}

	.thumbnail-container{
		max-width:50%;
		height:80px; 
		margin:0 2px;
		cursor:pointer;
		position: relative;
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
	}

	.thumbnail-overlay{
		width:100%;
		height:100%;
		position: absolute;
		background: transparent;
		top:0;
		transition: background ease 0.25s;
	}

	.thumbnail-selected .thumbnail-overlay, .thumbnail-container:hover .thumbnail-overlay{
		background-color:black;
		opacity:0.5;

	}

	.show_thumnails{
		max-height: 150px !important;
	}

	.gallery-controller{
		padding: 10px;
		width: 100%;
		color: rgba(0,0,0,0.87);
    	/*background-color: rgb(250,250,250);*/
    	box-sizing: border-box;
	}
	.gallery-delete-caption{
		cursor: pointer;
		top:2%;
		right:2%;
		transition: right ease-in-out .5s;
	}
	.gallery-edit-icons{
		position:absolute;
		cursor: pointer;
		top:0;
		right:0;
		padding: 10px;
		transition: right ease-in-out .5s;
	}

	.gallery-modal:hover .gallery-edit-icons{
		right:0;
		top: 0;
	}


	.gallery-panel{
		width:100%;
		align-items: center;
	}

	.gallery-thumbnails{
		overflow: auto;
		display:flex;
		flex-wrap: wrap;
		max-height: 0;
		transition: max-height ease-in-out .5s;
	}
	.z-photo-gallery .drop-box{
		padding: 10px !important;
	}
	.gallery-modal{
		border-radius: 30px;
		position: relative;
		overflow:hidden;
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%) !important;
	}


	.gallery-modal-image{
		margin: 25px 0;
		position: relative;
	}
	.gallery-modal-image img{
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		transition: opacity 0.5s, transform 0.5s;
	}

	.overlay-image{
		opacity: 0;
		transform: scale(2);

	}

	.overlay-container .display-image{
		opacity: 0;
		transform: scale(2);
	}

	.overlay-container .overlay-image{
		opacity: 1;
		transform: scale(1);
	}

	.gallery-panel-icon{
		cursor:pointer;
	}

	.gallery-panel-icon:hover .material-icons{
		fill: #89bd48 !important;
		color: #89bd48 !important;
	}

	.gallery-modal-delete, .gallery-thumbnail-delete, .gallery-left-arrow, .gallery-right-arrow, .gallery-delete-caption, .gallery-edit-icon, .gallery-modal-description{
		position: absolute;
	}

	.gallery-delete-caption:hover svg{
		color: rgb(210, 92, 61) !important;
		fill: rgb(210, 92, 61) !important;
	}

	.gallery-left-arrow{
		cursor: pointer;
		top:40%;
		left:-50%;
		height:40px;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 10px;
		transition: left ease-in-out .5s;
	}

	.gallery-right-arrow{
		cursor: pointer;
		top:40%;
		right:-50%;
		height:40px;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 10px;
		transition: right ease-in-out .5s;
	}

	.gallery-left-arrow, .gallery-right-arrow, .gallery-edit-icon .material-icons{
		fill: rgb(250, 250, 250) !important;
		color: rgb(250, 250, 250) !important;
	}
	.gallery-modal:hover .check{
		right: 55px;
		top: 0;
	}
	.gallery-modal:hover .cross{
		right: 20px;
		top: 0;
	}
	.gallery-description-content{
		width:100%;
		bottom: 0px;
		position: absolute;
		text-align: left;
		font-size: 13px;
	}

	.gallery-modal .gallery-description-content textarea {
		border: none;
		resize: none;
		outline: none;
		color: rgb(250, 250, 250);
		background: rgba(0, 0, 0, 0.5);
		caret-color: white;
		overflow: auto;
    	vertical-align: top;
    	padding: 10px;
    	padding-bottom: 20px;
    	min-height:80px;
    	box-sizing: border-box;
	}

	.gallery-modal-delete, .gallery-thumbnail-delete{
		top:0;
		cursor: pointer;
		opacity: 0;
		width:100%;
		transition: opacity ease-in-out .5s;
	}

	.gallery-modal-description{
		width:100%;
		bottom: -100%;
		background-color: rgba(0, 0, 0, 0.5);
		color: rgb(250, 250, 250);
		transition: bottom ease-in-out .5s;
		max-height: 35%;
   	 	overflow: auto;
	}

	.gallery-modal:hover .gallery-left-arrow{
		left:0;
	}

	.gallery-modal:hover .gallery-right-arrow{
		right:0;
	}

	.gallery-modal:hover .gallery-modal-delete{
		opacity:1;
	}

	.thumbnail-container:hover .gallery-thumbnail-delete{
		opacity:1;
	}

	.gallery-modal:hover .gallery-modal-description, .gallery-static-caption{
		bottom: 0;
	}

	.gallery-left-arrow:hover, .gallery-right-arrow:hover, .gallery-edit-icon:hover{
		background-color: #89bd48
	}

	.gallery-pagination-container{
		letter-spacing: 1px;
		font-size: 16px;
	}

	.gallery-delete{
		background-color:black; 
		border-radius: 50%;
	}

	.z-photo-gallery .material-icons {
		  -webkit-touch-callout: none; /* iOS Safari */
		    -webkit-user-select: none; /* Safari */
		     -khtml-user-select: none; /* Konqueror HTML */
		       -moz-user-select: none; /* Old versions of Firefox */
		        -ms-user-select: none; /* Internet Explorer/Edge */
		            user-select: none; /* Non-prefixed version, currently
		                                  supported by Chrome, Edge, Opera and Firefox */
	}