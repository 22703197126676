#form-file-upload {
  background: #fff;
  border: 5px dashed #DDD;
  padding: 10px;
  min-height:100px;
  min-width: 360px;
  max-width: 600px;
  border-radius: 30px;
  position: relative;
  flex-direction: column;
}

#form-file-upload.drag-active{
  border-color: #1976d2;
}

#form-file-upload #drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}