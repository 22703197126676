html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 3px;

}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  height: 3px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
} 


body {
  font-family: "Jaldi";
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  padding: 40px 100px;
}

@media (max-width: 1024px) {
  .header {
    padding: 40px;
  }
}

.Product-info-description {
  font-size: 16px;
  line-height: 18px;
  color: #959595;

  margin: 10px 0 0 0;
}

.custom-carousel-img {
  object-fit: contain;
  height: 500px;
}

@media (max-width: 997px) {
  .custom-carousel-img {
    height: 200px;
  }
}

.buy-now {
  order: 0;
}

@media (max-width: 1024px) {
  .buy-now {
    order: 1
  }
}


.Product-info-price {
  order: 1;
  margin-left: auto;
  font-size: 36px;
  line-height: 60px;
}

@media (max-width: 1024px) {
  .Product-info-price {
    margin-left: 10px;
    font-size: 24px;
    line-height: 40px;
    order: 0;
  }
}

.Product-info-title {
  font-size: 60px;
  line-height: 70px;
  margin: 0;
}

@media (max-width: 1024px) {
  .Product-info-title{
    font-size: 30px;
    line-height: 53px;
  }
}

.Product-info-subtitle {
  font-size: 24px; 
  line-height: 40px;
}

.Product-info-subtitle.special {
  margin-left: 10px;
}

@media (max-width: 1024px) {
  .Product-info-subtitle.special {
    margin-left: 50px;
  }
}


@media (max-width: 1024px) {
  .Product-info-subtitle {
  font-size: 20px;
  line-height: 33px;
  }
}

.cover-book:hover {
  transform: scale(2);
}

@media (max-width: 1024px) {
  .cover-book:hover { transform: none;}
}


.cover-book-text {
  font-size: 22px;
  line-height: 40px;
}

@media (max-width: 1024px) {
  .cover-book-text {
    font-size: 16px;
    line-height: 32px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
